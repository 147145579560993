import React, { useRef } from 'react';
import { playSoundEffect } from 'src/utils/soundEffects';
import type { Race } from 'src/types/domain/Race';
import type { RaceEvent } from 'src/types/domain/RaceEvent';
import type { Marble } from 'src/types/domain/Marble';
import { GameCardLatestWinners } from './components/GameCardLatestWinners';
import { GameCardLapsTag } from './components/GameCardLapsTag';
import { GameCardMarblesTag } from './components/GameCardMarblesTag';
import { GameCardBackgroundImage } from './components/GameCardBackgroundImage';
import { GameCardTimer } from './components/GameCardTimer';
import { use3dHoverEffect } from 'src/hooks/use3dHoverEffect';
type Props = Readonly<{
  id: string;
  name: string;
  laps: number;
  totalMarbles: number;
  trackName: string;
  trackImage: string;
  trackModeName: string;
  latestWinners?: {
    raceId: string;
    marble: Marble;
  }[];
  latestRace: Race;
  latestEvent: RaceEvent;
  previewThumbnail?: string;
  onIntroductionClick: () => void;
}>;
export function GameCard({
  id,
  name,
  trackName,
  trackImage,
  trackModeName,
  latestWinners = [],
  latestRace,
  latestEvent,
  previewThumbnail,
  onIntroductionClick
}: Props) {
  const {
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove
  } = use3dHoverEffect();
  const handleIntroductionClick = () => {
    playSoundEffect('click');
    onIntroductionClick();
  };
  return <div aria-label={`View details for ${trackName}`} className={`game-lobby-races_entry ${latestEvent.eventType}`} key={id} onClick={() => {
    handleIntroductionClick();
  }} onKeyDown={event => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleIntroductionClick();
    }
  }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove} role="button" tabIndex={0} data-sentry-component="GameCard" data-sentry-source-file="GameCard.tsx">
      <div className="top">
        <div className="top-overlay">
          <div className="track-name">{trackName}</div>
          <div className="track-details">
            <span>{name}</span>
            <span> | </span>
            <span>{trackModeName}</span>
          </div>

          <div className="top-overlay__labels">
            {/* <GameCardMarblesTag marbleCount={totalMarbles} /> */}
            {/* <GameCardLapsTag laps={laps} /> */}
            <GameCardLatestWinners winners={latestWinners} data-sentry-element="GameCardLatestWinners" data-sentry-source-file="GameCard.tsx" />
          </div>

          <div className="track-events">
            <div className="button">
              <GameCardTimer race={latestRace} data-sentry-element="GameCardTimer" data-sentry-source-file="GameCard.tsx" />
            </div>
          </div>
        </div>
        <GameCardBackgroundImage previewThumbnail={previewThumbnail} trackImage={trackImage} trackName={trackName} data-sentry-element="GameCardBackgroundImage" data-sentry-source-file="GameCard.tsx" />
      </div>
    </div>;
}