import { useEffect, useState } from 'react';
import { GameCardComingSoon } from './GameCardComingSoon';
import type { TrackComingSoon } from 'src/types/infra/supabase';
import { createClient } from 'src/services/supabase/client';
export function GameCardContainerComingSoon() {
  const [trackComingSoon, setTrackComingSoon] = useState<TrackComingSoon[]>([]);
  useEffect(() => {
    const fetchTrackComingSoon = async () => {
      const supabase = createClient();
      const {
        data
      } = await supabase.from('track_coming_soon').select('*').returns<TrackComingSoon[]>();
      setTrackComingSoon(data ?? []);
    };
    void fetchTrackComingSoon();
  }, []);
  return <>
      {trackComingSoon.map(track => <GameCardComingSoon key={track.id} id={track.id} image={track.image} name={track.name} />)}
    </>;
}