import type { MouseEvent } from 'react';
import { memo, useState, useCallback } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';
import IdleTimerBanner from 'src/components/IdleTimerBanner';
import { playSoundEffect } from 'src/utils/soundEffects';
import { isUnknownGame } from 'src/types/domain/Game';
import { useGetGameById, useGetCurrentLanguage } from 'src/store/game';
import { useClientSideTranslation } from 'src/services/i18n/client';
import { getLastWinners } from 'src/utils/raceUtils';
import TimesIcon from 'src/app/assets/icons/ico-times.svg';
import SignInIcon from 'src/app/assets/icons/ico-sign-in.svg';
import styles from 'src/app/assets/css/modules/game-teaser-modal.module.scss';
import { GameCardLatestWinners } from './GameCard/components/GameCardLatestWinners';
const ShowBroadcast = memo(dynamic(() => import('src/components/ShowBroadcast'), {
  ssr: false
}));
type Props = Readonly<{
  gameId: string;
  onClose: () => void;
  onViewerCountChange: (trackId: string, viewerCount: number) => void;
}>;
export default function GameTeaserModal({
  gameId,
  onClose,
  onViewerCountChange
}: Props) {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const [isIdle, setIsIdle] = useState(false);
  const [hasRealCommentator, setHasRealCommentator] = useState(false);
  const router = useRouter();
  const game = useGetGameById(gameId);
  if (isUnknownGame(game)) {
    return null;
  }
  const latestWinners = getLastWinners(game.races, game.marbles, 5);
  if (isUnknownGame(game)) {
    return null;
  }
  const handleIdleStateChange = (isIdle: boolean) => {
    setIsIdle(isIdle);
  };
  const handleRealCommentatorPresenceChange = useCallback((isPresent: boolean) => {
    setHasRealCommentator(isPresent);
  }, []);
  const handleClose = () => {
    playSoundEffect('click');
    onClose();
  };
  const handleOutsideClick = (e: MouseEvent<HTMLDivElement>) => {
    // Check if the click was directly on the outer container
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };
  const handleReconnect = () => {
    window.location.reload();
  };
  const handlePlayClick = (raceConfigurationId?: string) => {
    playSoundEffect('click');
    if (raceConfigurationId) {
      router.push(`/${raceConfigurationId}`);
    }
  };
  return <div className={styles['track-modal__outer']} onClick={handleOutsideClick} data-sentry-component="GameTeaserModal" data-sentry-source-file="GameTeaserModal.tsx">
      <div className={styles['track-modal']}>
        <IdleTimerBanner onIdleStateChange={handleIdleStateChange} onReconnect={handleReconnect} data-sentry-element="IdleTimerBanner" data-sentry-source-file="GameTeaserModal.tsx" />

        <div className={styles['track-modal__inner']}>
          <div className={styles['marbles-game__preview-broadcast']}>
            <div className={styles['preview-overlay']}>
              <button aria-label="Close modal" className={styles['close-btn']} onClick={handleClose} type="button">
                <TimesIcon data-sentry-element="TimesIcon" data-sentry-source-file="GameTeaserModal.tsx" />
              </button>
              <div className={styles.details}>
                <div className={styles['track-name']}>{game.track.name}</div>
                <div className={styles['track-details']}>
                  {game.name}
                  <span> | </span>
                  {game.track.mode.name}
                </div>
                <GameCardLatestWinners winners={latestWinners} data-sentry-element="GameCardLatestWinners" data-sentry-source-file="GameTeaserModal.tsx" />
              </div>
            </div>
            <div className={styles['dolby-feed']}>
              <ShowBroadcast game={game} isIdle={isIdle} onVideoSourceCountChange={() => {}} onViewerCountChange={viewerCount => {
              onViewerCountChange(game.id, viewerCount);
            }} data-sentry-element="ShowBroadcast" data-sentry-source-file="GameTeaserModal.tsx" />
            </div>
          </div>
          <div className={styles['track-modal__content']}>
            <div className={styles.buttons}>
              <button className={styles.btn} onClick={() => {
              handlePlayClick(game.id);
            }} type="button">
                <SignInIcon data-sentry-element="SignInIcon" data-sentry-source-file="GameTeaserModal.tsx" />
                <span>{t('Play')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>;
}