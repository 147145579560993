'use client'
import { Console, Effect, pipe } from 'effect'
import { useCallback, useState } from 'react'
import { getActiveGames } from 'src/services/supabase/queries'
import type { MarbleGame } from 'src/types/domain/Game'
import { useGameActions, useGetGameIds } from 'src/store/game'

export function useGames() {
  const [allGamesFetched, setAllGamesFetched] = useState<boolean>(false)
  const gameActions = useGameActions()
  const gameIds = useGetGameIds()

  const fetchCurrentGames = useCallback(async () => {
    const games = await pipe(getActiveGames(), Effect.tapError(Console.error), Effect.runPromise)

    const gamesRecord = games.reduce<Record<string, MarbleGame>>((acc, game) => {
      acc[game.id] = game
      return acc
    }, {})

    gameActions.setGames(gamesRecord)
    setAllGamesFetched(true)
  }, [gameActions])

  // const gameList = Array.fromIterable(Object.values(gameMap))

  return {
    // gameIdList: games.allIds,
    // gamesById: games.byId,
    // hasGames: gameList.length > 0,
    // setGames,
    // updateGameById,
    hasGames: gameIds.length > 0,
    fetchCurrentGames,
    allGamesFetched
    // updateTrack,
    // updateTrackMode
  }
}
