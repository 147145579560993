import { useState } from 'react';
import { GameCardContainer } from 'src/components/GameCard';
import GameTeaserModal from '../GameTeaserModal';
import FireIcon from 'src/app/assets/icons/ico-fire.svg';
import { GameCardContainerComingSoon } from '../GameCardComingSoon';
type Props = Readonly<{
  title?: string;
  gameIds: string[];
}>;
export function ShowGames({
  title,
  gameIds
}: Props) {
  const [modalGameId, setModalGameId] = useState<string | undefined>();
  const [trackViewerCounts, setTrackViewerCounts] = useState<Record<string, number>>({});

  // const [trackThumbnails, setTrackThumbnails] = useState<
  //   Record<
  //     string,
  //     {
  //       loading: boolean
  //       prevThumbnailUrl: string
  //     }
  //   >
  // >()

  const handleViewerCountChange = (trackId: string, viewerCount: number) => {
    setTrackViewerCounts(prevCounts => ({
      ...prevCounts,
      [trackId]: viewerCount
    }));
  };
  const handleCloseModal = () => {
    setModalGameId(undefined);
  };

  // useEffect(() => {
  //   gameIds.forEach((gameId) => {
  //     // const activeRaceConfiguration = getActiveRaceConfiguration(tracks, track.id)
  //     const prevThumbnailData = trackThumbnails?.[gameId] || {
  //       loading: false,
  //       prevThumbnailUrl: null
  //     }

  //     if (game.previewThumbnail && game.previewThumbnail !== prevThumbnailData.prevThumbnailUrl) {
  //       const previewThumbnail = game.previewThumbnail
  //       setTrackThumbnails((prevThumbnails) => ({
  //         ...prevThumbnails,
  //         [gameId]: {
  //           loading: true,
  //           prevThumbnailUrl: previewThumbnail
  //         }
  //       }))

  //       const img = document.createElement('img')
  //       img.src = game.previewThumbnail
  //       img.onload = () => {
  //         setTimeout(() => {
  //           setTrackThumbnails((prevThumbnails) => ({
  //             ...prevThumbnails,
  //             [gameId]: {
  //               loading: false,
  //               prevThumbnailUrl: previewThumbnail
  //             }
  //           }))
  //         }, 500)
  //       }
  //     }
  //   })
  // }, [games, trackThumbnails])

  return <>
      <div className="section-heading">
        <div className="icon">
          <FireIcon data-sentry-element="FireIcon" data-sentry-source-file="ShowGames.tsx" />
        </div>
        <h2>{title}</h2>
        {/* <div className="track-categories">
                <button type="button" className="active">
                  <span className="icon">
                    <ThLargeIcon />
                  </span>
                  <span>All</span>
                </button>
                <button type="button">
                  <span className="icon">
                    <SunIcon />
                  </span>
                  <span>Day Time</span>
                </button>
                <button type="button">
                  <span className="icon">
                    <MoonStarsIcon />
                  </span>
                  <span>Night Time</span>
                </button>
                <button type="button">
                  <span className="icon">
                    <CocktailIcon />
                  </span>
                  <span>Party</span>
                </button>
              </div> */}
      </div>
      <div className="game-lobby-races">
        {gameIds.map(gameId => {
        const introductionClick = () => {
          setModalGameId(gameId);
        };
        return <GameCardContainer gameId={gameId} key={gameId} onIntroductionClick={introductionClick} />;
      })}
        <GameCardContainerComingSoon data-sentry-element="GameCardContainerComingSoon" data-sentry-source-file="ShowGames.tsx" />
      </div>
      {modalGameId ? <GameTeaserModal gameId={modalGameId} onClose={handleCloseModal} onViewerCountChange={handleViewerCountChange} /> : null}
    </>;
}