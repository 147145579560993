import type { Race } from 'src/types/domain/Race';
import { useTimer } from 'src/hooks/useTimer';
import ClockIcon from 'src/app/assets/icons/ico-clock.svg';
import { isRaceSettled } from 'src/utils/raceUtils';
type Props = Readonly<{
  race: Race;
}>;
export function GameCardTimer({
  race
}: Props) {
  const {
    eventInfo
  } = useTimer({
    race
  });
  if (!eventInfo) {
    return null;
  }
  const isNotSettled = !isRaceSettled(race);
  return <>
      <span className="event-label">{eventInfo.label}</span>
      {isNotSettled ? <div className="time">
          <ClockIcon />
          <span>{eventInfo.getTime()}</span>
        </div> : null}
    </>;
}