import { useGetCurrentLanguage } from 'src/store/game';
import type { Winner } from 'src/utils/raceUtils';
import { useClientSideTranslation } from 'src/services/i18n';
import CrownIcon from 'src/app/assets/icons/ico-crown.svg';
import { MarbleItem } from '../../MarbleItem';
type Props = Readonly<{
  winners: Winner[];
}>;
export function GameCardLatestWinners({
  winners
}: Props) {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const hasWinners = winners.length > 0;
  return <div className="card-label card-label-latest-winners" data-sentry-component="GameCardLatestWinners" data-sentry-source-file="GameCardLatestWinners.tsx">
      <span className="result-icon">
        <CrownIcon data-sentry-element="CrownIcon" data-sentry-source-file="GameCardLatestWinners.tsx" />
      </span>
      <div className="results-marbles">
        {hasWinners ? winners.map(winner => <MarbleItem key={winner.raceId} marble={winner.marble} />) : <span>{t('No winners yet')}</span>}
      </div>
    </div>;
}