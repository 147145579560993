'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { ShowGamesContainer } from 'src/components/ShowGames/index';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { playBackgroundMusic, stopBackgroundMusic } from 'src/utils/soundEffects';
import { SoundSettingsManager } from 'src/utils/soundSettingManager';
import { GameTopToolbarContainer } from 'src/components/GameTopToolbar';
import { GameHeroContainer } from 'src/components/GameHero';
import { RightSidebarContainer } from 'src/components/RightSidebar';
import { AnonymousPlayerView } from 'src/components/AnonymousPlayerView';
import { unknownGame } from 'src/types/domain/Game';
import { useLobbyListeners } from 'src/hooks/useLobbyListeners';
import { useGetAuthenticationState } from 'src/store/player';
import { useOneTimeLogin } from 'src/hooks/useOneTimeLogin';
import { useGames } from 'src/hooks/useGames';
import { useGameActions, useGetCurrentGameId, useGetCurrentLanguage } from 'src/store/game';
import { useClientSideTranslation } from 'src/services/i18n/client';
import useDeviceOrientation from 'src/hooks/useDeviceOrientation';
import { OrientationMessage } from 'src/components/OrientationMessage';
import FireIcon from './assets/icons/ico-fire.svg';
type Props = Readonly<{
  searchParams?: Record<string, string | undefined>;
}>;
export default function App({
  searchParams
}: Props) {
  useOneTimeLogin(searchParams?.otl);
  const authenticationState = useGetAuthenticationState();
  const gameActions = useGameActions();
  const currentGameId = useGetCurrentGameId();
  const {
    fetchCurrentGames,
    hasGames,
    allGamesFetched
  } = useGames();
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const {
    isLandscape,
    isMobile
  } = useDeviceOrientation();
  useLobbyListeners();
  useEffect(() => {
    const soundSettings = SoundSettingsManager.getInstance();
    const settings = soundSettings.getSettings();

    // Only start music if neither master nor music is muted
    if (!settings.muted.master && !settings.muted.music) {
      playBackgroundMusic();
    }
    return () => {
      stopBackgroundMusic();
    };
  }, []);
  // Reset potential current game
  useEffect(() => {
    if (currentGameId) {
      gameActions.setCurrentGame(unknownGame);
    }
  }, [currentGameId, gameActions]);
  useEffect(() => {
    void fetchCurrentGames();
  }, [fetchCurrentGames]);

  // const [selectedCommentator, setSelectedCommentator] = useState(() => {
  //   if (typeof window !== 'undefined') {
  //     return localStorage.getItem('selectedCommentator') || 'Wayne_20240711'
  //   }
  //   return 'Wayne_20240711'
  // })

  const [showAvatar, setShowAvatar] = useState(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('showAvatar') === 'true';
    }
    return false;
  });

  // useEffect(() => {
  //   localStorage.setItem('selectedCommentator', selectedCommentator)
  // }, [selectedCommentator])

  useEffect(() => {
    localStorage.setItem('showAvatar', showAvatar.toString());
  }, [showAvatar]);
  if (authenticationState === 'AnonymousPlayer') {
    return <AnonymousPlayerView />;
  }
  if (!hasGames || authenticationState === 'UnknownPlayer') {
    return <LoadingIndicator />;
  }
  if (isMobile && isLandscape) {
    return <OrientationMessage />;
  }
  return <main className="marbles-game" data-sentry-component="App" data-sentry-source-file="page.tsx">
      <RightSidebarContainer
    // selectedCommentator={selectedCommentator}
    // setSelectedCommentator={setSelectedCommentator}
    setShowAvatar={setShowAvatar} showAvatar={showAvatar} showStatsAndRules={false} data-sentry-element="RightSidebarContainer" data-sentry-source-file="page.tsx" />

      <GameTopToolbarContainer data-sentry-element="GameTopToolbarContainer" data-sentry-source-file="page.tsx" />
      <div className="track-sidebar__outer">
        <div className="track-sidebar">
          <div className="logo">
            <Image alt="Marbles" draggable="false" height={40} src="/marbles_symbol-white.webp" width={40} data-sentry-element="Image" data-sentry-source-file="page.tsx" />
          </div>
          <nav>
            <button className="active" type="button">
              <span className="inner">
                <span className="icon">
                  <FireIcon data-sentry-element="FireIcon" data-sentry-source-file="page.tsx" />
                </span>
              </span>
            </button>
            {/* <button type="button">
                  <span className="inner">
                    <span className="icon">
                      <HeartAltIcon />
                    </span>
                  </span>
                </button>
                <button type="button">
                  <span className="inner">
                    <span className="icon">
                      <BallPileIcon />
                    </span>
                  </span>
                </button>
              </nav>
              <nav>
                <button type="button">
                  <span className="inner">
                    <span className="icon">
                      <UserHeadsetIcon />
                    </span>
                  </span>
                </button> */}
          </nav>
        </div>
      </div>

      <GameHeroContainer data-sentry-element="GameHeroContainer" data-sentry-source-file="page.tsx" />

      <div className="track_inner-content">
        {Boolean(allGamesFetched) && <ShowGamesContainer title={t('All Live Tracks')} />}
      </div>
    </main>;
}