'use client';

import { forwardRef, type ForwardedRef } from 'react';
type Props = Readonly<{
  assetName: string;
}>;
function HeroBackgroundComponent({
  assetName
}: Props, ref: ForwardedRef<HTMLVideoElement>) {
  return <div className="track-hero__background" data-sentry-component="HeroBackgroundComponent" data-sentry-source-file="HeroBackground.tsx">
      <video autoPlay muted playsInline poster={`/tracks-backgrounds/${assetName}.webp`} ref={ref}>
        <source src={`/tracks-backgrounds/${assetName}-video.mp4`} type="video/mp4" />
        <source src={`/tracks-backgrounds/${assetName}-video.webm`} type="video/webm" />
      </video>
    </div>;
}
const HeroBackground = forwardRef<HTMLVideoElement, Props>(HeroBackgroundComponent);
export default HeroBackground;