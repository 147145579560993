import Image from 'next/image';
type Props = Readonly<{
  trackName: string;
  previewThumbnail?: string;
  trackImage: string;
}>;
export function GameCardBackgroundImage({
  trackName,
  previewThumbnail,
  trackImage
}: Props) {
  return <>
      {previewThumbnail ? <Image alt={`${trackName} Thumbnail`} className="track-image fade-in" draggable="false" height={225} sizes="100vw" src={previewThumbnail || '/marbles-racetrack-placeholder.webp'} style={{
      objectFit: 'cover'
    }} width={400} /> : null}
      <Image alt={`${trackName} Thumbnail`} className="preview-image fade-in" draggable="false" height={225} sizes="100vw" src={trackImage} style={{
      objectFit: 'cover'
    }} width={400} data-sentry-element="Image" data-sentry-source-file="GameCardBackgroundImage.tsx" />
    </>;
}