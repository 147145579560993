import Image from 'next/image';
import { useGetCurrentLanguage } from 'src/store/game';
import { useClientSideTranslation } from 'src/services/i18n';
import { use3dHoverEffect } from 'src/hooks/use3dHoverEffect';
type Props = Readonly<{
  id: string;
  name: string;
  image: string;
}>;
export function GameCardComingSoon({
  id,
  name,
  image
}: Props) {
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  const {
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove
  } = use3dHoverEffect();
  return <div className={`game-lobby-races_entry upcoming`} key={id} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove} role="presentation" tabIndex={0} data-sentry-component="GameCardComingSoon" data-sentry-source-file="GameCardComingSoon.tsx">
      <div className="top">
        <div className="top-overlay">
          <div className="track-name">{name}</div>
          <div className="track-details">
            <span>{t('Coming Soon')}</span>
          </div>
        </div>
        <Image alt={`${name} Thumbnail`} className="preview-image fade-in" draggable="false" height={225} sizes="100vw" src={image} style={{
        objectFit: 'cover'
      }} width={400} data-sentry-element="Image" data-sentry-source-file="GameCardComingSoon.tsx" />
      </div>
    </div>;
}