import { useEffect, useMemo } from 'react'
import type { Channel } from 'phoenix'
import { usePlayerAPI } from 'src/hooks/usePlayerAPI'
import { useBetActions } from 'src/store/bet'
import { useGameActions } from 'src/store/game'
import { useGetCurrentPlayerId, useGetCurrentPlayerToken, usePlayerActions } from 'src/store/player'
import { useUIActions } from 'src/store/ui'
import type { Actions } from 'src/utils/websocketEventHandlers'
import {
  handleBetAccepted,
  handleBetRejected,
  handleBetVoided,
  handleBetWon,
  handleMarbleCreated,
  handleMarbleDeleted,
  handleMarbleUpdated,
  handleRaceConfigurationCreated,
  handleRaceConfigurationUpdated,
  handleRaceCreated,
  handleRaceEventCreated,
  handleRaceEventUpdated,
  handleRaceUpdated,
  handleTrackCreated,
  handleTrackModeCreated,
  handleTrackModeUpdated,
  handleTrackUpdated
} from 'src/utils/websocketEventHandlers'
import {
  useGetPlayerChannel,
  useGetRaceChannel,
  useGetSocket,
  useSocketActions
} from 'src/store/socket'

export const useLobbyListeners = () => {
  const socketActions = useSocketActions()
  const socket = useGetSocket()
  const playerChannel = useGetPlayerChannel()
  const raceChannel = useGetRaceChannel()
  const playerId = useGetCurrentPlayerId()
  const betActions = useBetActions()
  const gameActions = useGameActions()
  const playerActions = usePlayerActions()
  const uiActions = useUIActions()
  const { updateBalance } = usePlayerAPI()
  const token = useGetCurrentPlayerToken()

  const hasSocket = Boolean(socket)

  const isPlayerChannelJoined = playerChannel?.state === 'joined'
  const isRaceChannelJoined = raceChannel?.state === 'joined'

  const actions: Actions = useMemo(
    () => ({
      bet: betActions,
      player: playerActions,
      ui: uiActions,
      game: gameActions,
      socket: socketActions
    }),
    [betActions, playerActions, uiActions, gameActions, socketActions]
  )

  useEffect(() => {
    if (!token) return

    if (!hasSocket) {
      actions.socket.createSocket(
        process.env.NEXT_PUBLIC_PHOENIX_SOCKET_SCHEME,
        process.env.NEXT_PUBLIC_PHOENIX_SOCKET_URL,
        token
      )
    }
  }, [hasSocket, actions, token])

  // PLAYER CHANNEL
  useEffect(() => {
    if (!hasSocket || !playerId) return

    if (!isPlayerChannelJoined) {
      void actions.socket.joinPlayerChannel(playerId)
    } else {
      setupChannelListeners(playerChannel, [
        { event: 'bet:accepted', handler: handleBetAccepted(actions, updateBalance) },
        { event: 'bet:rejected', handler: handleBetRejected(actions, updateBalance) },
        { event: 'bet:won', handler: handleBetWon(actions, updateBalance) },
        { event: 'bet:voided', handler: handleBetVoided(actions, updateBalance) }
      ])
    }
  }, [hasSocket, playerId, isPlayerChannelJoined, actions, updateBalance, playerChannel])

  // RACE CHANNEL
  useEffect(() => {
    if (!hasSocket) return

    if (!isRaceChannelJoined) {
      void actions.socket.joinRacesChannel()
    } else {
      setupChannelListeners(raceChannel, [
        // Race Configuration
        { event: 'race-configuration:created', handler: handleRaceConfigurationCreated(actions) },
        { event: 'race-configuration:updated', handler: handleRaceConfigurationUpdated(actions) },
        // Race
        { event: 'race:created', handler: handleRaceCreated(actions) },
        { event: 'race:updated', handler: handleRaceUpdated(actions) },
        // Race Event
        { event: 'race-event:created', handler: handleRaceEventCreated(actions) },
        { event: 'race-event:updated', handler: handleRaceEventUpdated(actions) },
        // Track
        { event: 'track:created', handler: handleTrackCreated(actions) },
        { event: 'track:updated', handler: handleTrackUpdated(actions) },
        // Track Mode
        { event: 'track-mode:created', handler: handleTrackModeCreated(actions) },
        { event: 'track-mode:updated', handler: handleTrackModeUpdated(actions) },
        // Marble
        { event: 'marble:created', handler: handleMarbleCreated(actions) },
        { event: 'marble:updated', handler: handleMarbleUpdated(actions) },
        { event: 'marble:deleted', handler: handleMarbleDeleted(actions) }
      ])
    }
  }, [hasSocket, isRaceChannelJoined, actions, raceChannel])
}

const setupChannelListeners = (
  channel: Channel,
  events: { event: string; handler: (data: unknown) => void }[]
) => {
  events.forEach(({ event, handler }) => {
    channel.on(event, handler)
  })
  return () => {
    events.forEach(({ event }) => {
      channel.off(event)
    })
  }
}
