'use client';

import { useRouter } from 'next/navigation';
import { useClientSideTranslation } from 'src/services/i18n';
import { useGetCurrentLanguage } from 'src/store/game';
import SignInIcon from 'src/app/assets/icons/ico-sign-in.svg';
type Props = Readonly<{
  gameId: string;
  title: string;
  description: string;
}>;
export function HeroContent({
  gameId,
  title,
  description
}: Props) {
  const router = useRouter();
  const {
    t
  } = useClientSideTranslation(useGetCurrentLanguage());
  return <div className="track-hero__content" data-sentry-component="HeroContent" data-sentry-source-file="HeroContent.tsx">
      <div className="hero__content-right">
        <h2 className="title">{title}</h2>
        <p className="description">{description}</p>

        <button className="button" onClick={() => {
        router.push(`/${gameId}`);
      }} type="button">
          <SignInIcon data-sentry-element="SignInIcon" data-sentry-source-file="HeroContent.tsx" />
          <span>{t('Play')}</span>
        </button>
      </div>
    </div>;
}