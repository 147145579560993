import { isUnknownGame } from 'src/types/domain/Game';
import { useGetGameById } from 'src/store/game';
import { getLastWinners, getLatestEvent } from 'src/utils/raceUtils';
import { GameCard } from './GameCard';
type Props = Readonly<{
  gameId: string;
  onIntroductionClick: () => void;
}>;
export function GameCardContainer({
  gameId,
  onIntroductionClick
}: Props) {
  const game = useGetGameById(gameId);
  if (isUnknownGame(game)) {
    return null;
  }
  const latestWinners = getLastWinners(game.races, game.marbles, 5);
  const latestRace = game.races[0];
  const latestEvent = getLatestEvent(latestRace.raceEvents);
  if (!latestEvent) {
    return null;
  }
  return <GameCard id={gameId} key={gameId} laps={game.laps} latestEvent={latestEvent} latestRace={latestRace} latestWinners={latestWinners} name={game.name} onIntroductionClick={onIntroductionClick} previewThumbnail={game.previewThumbnail} totalMarbles={game.totalMarbles} trackImage={game.track.image} trackModeName={game.track.mode.name} trackName={game.track.name} data-sentry-element="GameCard" data-sentry-component="GameCardContainer" data-sentry-source-file="GameCardContainer.tsx" />;
}