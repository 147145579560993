'use client';

import type { Hero } from 'src/types/infra/supabase';
import type { NormalizedMarbleGame } from 'src/store/types';
import { HeroContent } from './components/HeroContent';
import HeroBackground from './components/HeroBackground';
interface GameSlide {
  hero: Hero;
  game: NormalizedMarbleGame;
}
type Props = Readonly<{
  slides: GameSlide[];
  activeIndex: number;
  videoRefs: React.MutableRefObject<(HTMLVideoElement | null)[]>;
}>;
export function GameHero({
  slides,
  activeIndex,
  videoRefs
}: Props) {
  return <div className="track-hero" data-sentry-component="GameHero" data-sentry-source-file="GameHero.tsx">
      {slides.map((slide, index) => <div className={`track-hero__slide absolute inset-0 transition-opacity duration-1000 ease-in-out ${index === activeIndex ? 'opacity-100 z-10' : 'opacity-0 z-0'}`} key={slide.hero.id}>
          <HeroContent description={slide.hero.description} gameId={slide.game.id} title={slide.hero.title} />
          <HeroBackground assetName={slide.hero.image} ref={el => {
        videoRefs.current[index] = el;
      }} />
        </div>)}
    </div>;
}