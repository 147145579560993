'use client';

import { useEffect, useRef, useState } from 'react';
import { pipe, Array as A, Option as O } from 'effect';
import type { Hero } from 'src/types/infra/supabase';
import { createClient } from 'src/services/supabase/client';
import { useGetGameIds, useGetGames } from 'src/store/game';
import { GameHero } from './GameHero';
export function GameHeroContainer() {
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [heroes, setHeroes] = useState<Hero[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const gameIds = useGetGameIds();
  const games = useGetGames();
  useEffect(() => {
    const fetchPageHero = async () => {
      const supabase = createClient();
      const {
        data
      } = await supabase.from('page_hero').select('*').eq('active', true).returns<Hero[]>();
      setHeroes(data ?? []);
    };
    void fetchPageHero();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(prevIndex => (prevIndex + 1) % heroes.length);

      // Restart the video of the current slide
      const currentVideo = videoRefs.current[(activeIndex + 1) % heroes.length];
      if (currentVideo) {
        currentVideo.currentTime = 0;
        void currentVideo.play().catch(error => {
          console.error('Error playing video:', error);
        });
      }
    }, 4800); // Change every +- 5 seconds
    return () => {
      clearInterval(interval);
    };
  }, [heroes.length, activeIndex]);
  if (heroes.length === 0) {
    // Done to keep the height of the slider at all times
    return <div className="track-hero" />;
  }
  const slides = pipe(gameIds, A.map(id => games[id]), A.map(O.fromNullable), A.getSomes, A.map(game => {
    const hero = heroes.find(hero => hero.track_id === game.trackId);
    return hero ? O.some({
      hero,
      game
    }) : O.none();
  }), A.getSomes);
  return <GameHero activeIndex={activeIndex} slides={slides} videoRefs={videoRefs} data-sentry-element="GameHero" data-sentry-component="GameHeroContainer" data-sentry-source-file="GameHeroContainer.tsx" />;
}